<template>
	<div class="modal" :id="name">
		<div class="modal__close" @click="closeModal($event);">
			<Icon icon="close" size="large" />
		</div>
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		name: String,
	},
	data(){
		return{
			
		}
	},
  beforeMount(){
    this.id = this.name
  },
	methods: {
    closeModal: function(event) {
      var element = event.currentTarget.parentElement.id;
      document.getElementById(element).classList.remove('modal--open')
      document.body.classList.remove('modal--open');
      this.$emit("closedModal");
    },
	},
}

</script>

<style lang="scss" scoped>
.modal{
	height: var(--modal-height);
	width: var(--modal-width);
	top: var(--modal-start-top);
	opacity: var(--modal-start-opacity);
	left: var(--modal-left);
	z-index: -1;
	position: fixed;
	background: var(--modal-background);
	overflow-y: scroll;
	transition: var(--modal-transition);
}
.modal--open{
	opacity: var(--modal-end-opacity);
	z-index: 10;
	top: var(--modal-end-top);
}
.modal__close{
	height: var(--modal-close-height);
	width: var(--modal-close-width);
	border: var(--modal-close-border);
	background: var(--modal-close-background);
	position: fixed;
	top: var(--modal-close-top);
	right: var(--modal-close-right);
	cursor: pointer;
	z-index: 10;
}
</style>

<!-- Used in : DS, SD -->